import { StaticImage } from 'gatsby-plugin-image'
import useAnalytics from '../../hooks/analytics'
import { useSiteMetadata } from '../../hooks/site'
import Button from '../button'
import Card, { CardContent } from '../card'
import Link from '../link'
import Page from '../page/page'
import TextField from '../text-field'
import Typography from '../typography'
import {
  pageContactForm,
  pageContactInfo,
  pageContactLocation,
  pageContactPage,
} from './page-contact.module.scss'

const PageContact = () => {
  const track = useAnalytics()
  const { contact } = useSiteMetadata()

  const submit = () => track('generate_lead')

  return (
    <Page container className={pageContactPage}>
      <Card className={pageContactForm}>
        <CardContent>
          <form
            method="POST"
            action={process.env.GATSBY_GET_FORM_CONTACT_FORM_URL}
          >
            <Typography variant="h4" component="h1">
              Reach out
            </Typography>
            <TextField
              required
              autoFocus
              name="name"
              pattern=".{3,}"
              title="3 characters minimum"
              label="Name"
            />
            <TextField
              required
              name="email"
              type="email"
              pattern=".{5,}"
              title="5 characters minimum"
              label="E-Mail"
            />
            <TextField
              required
              multiline
              name="project-description"
              rows={6}
              label="Tell us about your project"
            />
            <Button type="submit" variant="primary" onClick={submit}>
              Send message
            </Button>
          </form>
        </CardContent>
      </Card>
      <div className={pageContactInfo}>
        <section>
          <Typography variant="h5">Talk to us</Typography>
          <Link href={`mailto:${contact.email}`}>
            <Typography variant="body1" color="inherit">
              {contact.email}
            </Typography>
          </Link>
          <Link href={`tel:${contact.phoneNumber}`}>
            <Typography variant="body1" color="inherit">
              {contact.formattedPhoneNumber}
            </Typography>
          </Link>
        </section>
        <section>
          <Typography variant="h5">Pay us a visit</Typography>
          <Link external href={contact.address.url}>
            <Typography variant="body1" color="inherit">
              {contact.address.name}
            </Typography>
          </Link>
          <Link external href={contact.address.url}>
            <StaticImage
              src="../../images/staticmap.png"
              alt={contact.address.name}
              className={pageContactLocation}
            />
          </Link>
        </section>
      </div>
    </Page>
  )
}

export default PageContact
