import classNames from 'classnames'
import {
  ChangeEventHandler,
  InputHTMLAttributes,
  TextareaHTMLAttributes,
} from 'react'
import { textField } from './text-field.module.scss'

interface TextFieldProps
  extends Pick<
      InputHTMLAttributes<HTMLInputElement>,
      | 'title'
      | 'required'
      | 'pattern'
      | 'name'
      | 'type'
      | 'maxLength'
      | 'minLength'
      | 'autoFocus'
    >,
    Pick<TextareaHTMLAttributes<HTMLTextAreaElement>, 'rows'> {
  label: string
  multiline?: boolean
  className?: string
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

const TextField = ({
  label,
  required,
  rows,
  multiline,
  className,
  ...rest
}: TextFieldProps) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label className={classNames(textField, className)}>
    {label}
    {multiline ? (
      <textarea required={required} rows={rows} {...rest} />
    ) : (
      <input required={required} {...rest} />
    )}
  </label>
)

TextField.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  type: 'text',
}

export default TextField
